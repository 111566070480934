import { generateUrl as generateUrl2 } from './utils';

export default {
  // 获取博客列表
  getSixHorseApi() {
    return generateUrl2('/api/system/sixhorse/api', false);
  },
  // 聚合页详情
  getC2CAppGamePage() {
    return generateUrl2('/api/v2/market/game_agg_page', false);
  },
  // 游戏详情页
  getC2CAppDetail() {
    return generateUrl2('/api/market/c2c/app_detail', false);
  },
  // 批量查询游戏的销售数据
  getC2cAppData() {
    return generateUrl2('/api/market/c2c/app_data', false);
  },
  // 查询游戏业务TKD
  getMarketGameAppServiceTkd() {
    return generateUrl2('/api/v2/market/game_app_service/tkd', false);
  },
  // 游戏详情页v2
  getMarketGameAppServiceCategory() {
    return generateUrl2('/api/v2/market/game_app_service/category', false);
  },
  // 游戏详情页v2
  geMarketGameAppServiceDetail() {
    return generateUrl2('/api/v2/market/game_app_service/detail_i18n', false);
  },
  // 获取角色详情
  geMarketGameAppServiceRoles() {
    return generateUrl2('/api/v2/market/game_app_service/check_roles', false);
  },
  // 游戏商品sku列表v2
  geMarketSku() {
    return generateUrl2('/api/v2/market/sku/', false);
  },
  // 游戏商品sku详情v2
  geMarketSkuDetail() {
    return generateUrl2('/api/v2/market/sku/detail', false);
  },
  postMarketBuyCartDetail() {
    return generateUrl2('/api/market/goods/buy_cart/detail', false);
  },
  // 卖家给买家增加标记
  postC2CUserSetMark() {
    return generateUrl2('/api/market/seller/user/set_mark', false);
  },
  // 卖家中心列表页
  getC2CBuyOrderList() {
    return generateUrl2('/api/market/seller/buy_order/list', false);
  },
  // 卖家中心订单详情页
  getC2CBuyOrderDetail() {
    return generateUrl2('/api/market/seller/buy_order/detail', false);
  },
  // 卖家中心订单状态处理
  getC2CBuyOrderUpdate() {
    return generateUrl2('/api/market/seller/buy_order/update', false);
  },
  // 通知买家补充信息
  getC2CBuyerSendSubmitNotice() {
    return generateUrl2('/api/market/seller/buy_order/send_submit_notice', false);
  },
  // 卖家中心退款原因
  getC2CRefundReasonList() {
    return generateUrl2('/api/market/seller/buy_order/refund_reason_list', false);
  },
  // 卖家中心发起退款
  getC2CSellerRefund() {
    return generateUrl2('/api/market/seller/buy_order/refund', false);
  },
  // 卖家中心ip地址
  getC2CSellerAddress() {
    return generateUrl2('/api/account/get_country', false);
  },
  // 卖家中心导出报表
  getC2CSellerDownload() {
    return generateUrl2('/api/market/seller/buy_order/download', false);
  },
  // 资金流水下载请求
  getC2CReqDownload() {
    return generateUrl2('/api/v1/asset/asset/log/req_download', false);
  },
  // 卖家中心出售订单下载请求
  getC2CSellerReqDownload() {
    return generateUrl2('/api/market/seller/buy_order/req_download', false);
  },
  // 查询表格状态及下载地址
  getC2CReportReqDownload() {
    return generateUrl2('/api/market/report/query_download_res', false);
  },
  // 卖家中心添加订单备注
  postC2CAddRemark() {
    return generateUrl2('/api/market/seller/buy_order/add_remark', false);
  },
  // 卖家中心添加问题标记
  postC2CAddMark() {
    return generateUrl2('/api/market/seller/buy_order/set_mark', false);
  },
  // 卖家中心删除问题标记
  postC2CRemoveMark() {
    return generateUrl2('/api/market/seller/buy_order/remove_mark', false);
  },
  getBuyOrderSubmitNoticeList() {
    return generateUrl2('/api/market/seller/buy_order/submit_notice_list', false);
  },
  // 卖家截卡器获取已登录的fc24账号
  getCutterLoginedAccount() {
    return generateUrl2('/api/fc24/get_logined_account', false);
  },
  // 卖家截卡器登录fc24账号
  postCutterLogin() {
    return generateUrl2('/api/fc24/login', false);
  },
  // 卖家截卡器退出登录fc24账号
  postCutterLoginOut() {
    return generateUrl2('/api/fc24/logout_account', false);
  },
  // 卖家截卡器获取登录fc24账号状态查询
  getCutterLoginResult() {
    return generateUrl2('/api/fc24/query_seller_login_result', false);
  },
  // fc24截卡&卖卡
  postBuyCard() {
    return generateUrl2('/api/fc24/req_buy_card', false);
  },
  // 卖家截卡状态查询
  getCutterBuyCardResult() {
    return generateUrl2('/api/fc24/query_buy_card_result', false);
  },
  // 计算修正截卡价格
  getCalcSellPrice() {
    return generateUrl2('/api/fc24/calc_sell_price', false);
  },
  // 获取当前订单进度及日志
  getCutterOrderProgress() {
    return generateUrl2('/api/fc24/order_progress', false);
  },
  // 查询可出售球员卡
  postCanSellCard() {
    return generateUrl2('/api/fc24/get_card', false);
  },
  // 绑定角色
  bindC2CKnivesoutRole() {
    return generateUrl2('/api/account/recharge_bind_role', false);
  },
  unbindC2CKnivesoutRole() {
    return generateUrl2('/api/account/recharge_unbind_role', false);
  },
  extraQueryProfile() {
    return generateUrl2('/api/market/buy_order/extra/query_profile', false);
  },
  reqBuyCard() {
    return generateUrl2('/api/fc24/req_buy_card', false);
  },
  queryBuyCardResult() {
    return generateUrl2('/api/fc24/query_buy_card_result', false);
  },
  getPointTaskList() {
    return generateUrl2('/api/point/task_list', false);
  },
  getPointTaskDetail() {
    return generateUrl2('/api/point/task_detail', false);
  },
};
