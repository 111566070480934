import { generateUrl } from './utils';
import goods from './goods';
import inventory from './inventory';
import buy from './buy';
import sell from './sell';
import bargain from './bargain';
import account from './account';
import feedback from './feedback';
import store from './store';
import community from './community';
import activity from './activity';
import share from './share';
import c2c from './c2c';
import lootbar from './lootbar';
import im from './im';
import coupon from './coupon';
import shoppingCart from './shoppingCart';

export { host } from './utils';

const api = {
  ...goods,
  ...inventory,
  ...buy,
  ...sell,
  ...bargain,
  ...account,
  ...feedback,
  ...store,
  ...community,
  ...activity,
  ...share,
  ...c2c,
  ...lootbar,
  ...im,
  ...coupon,
  ...shoppingCart,
  sample() {
    // 需要使用本地模拟接口的，第二个参数传 false
    return generateUrl('/api/sample', false);
  },
};
export default api;
