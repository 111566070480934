<template>
  <div class="w-checkbox" :class="[
    $slots.default ? 'w-checkbox--label' : '',
    type ? `w-checkbox--${type}` : '',
    checked ? 'is-checked' : '',
    checkboxDisabled ? 'is-disabled' : '',
  ]"
    @click="handleSelect">
    <div
      class="w-checkbox__input">
      <i v-if="img === 'png'" class="w-icon" :class="iconName"></i>
      <w-icon
        v-else
        class="w-checkbox__input-inner"
        :size="16" :name="iconName" />
    </div>
    <div class="w-checkbox__label">
      <slot v-if="$slots.default"></slot>
    </div>
  </div>
</template>

<script>
const CheckboxIconNameEnum = {
  checkbox: {
    on: 'icon_home_checkbox_checked',
    off: 'icon_home_checkbox',
  },
  selectbox2: {
    on: 'icon_step_success',
    off: 'icon_check_off',
  },
  selectbox: {
    on: 'icon_selectbox_selected',
    off: 'icon_selectbox_unselected',
  },
  selectboxpng: {
    on: 'icon_multi_select_on',
    off: 'icon_multi_select_off',
    disabled: 'icon_multi_select_disabled',
  },
};
export default {
  props: {
    value: Boolean,
    label: [String, Number],
    disabled: Boolean,
    type: String,
    iconType: {
      type: String,
      default: 'checkbox',
    },
    img: {
      type: String,
      default: 'svg', // 默认使用svg, 但特殊场景使用png
    },
  },
  computed: {
    iconName() {
      return CheckboxIconNameEnum[this.iconType][this.checked ? 'on' : 'off'];
    },
    checkboxDisabled() {
      return this.disabled;
    },
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    checked() {
      return this.val;
    },
  },
  methods: {
    handleSelect() {
      if (this.checkboxDisabled) return;
      this.val = !this.val;
    },
  },
};
</script>
