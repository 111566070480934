<template>
  <div class="w-message"
    :class="{
      [`w-message--${type}`]: type,
      'has-title': title,
      'is-collapsed': isCollapsed,
    }">
    <div class="w-message-main">
      <div class="w-message-header">
        <i class="w-message-icon"></i>
        <div v-if="title" class="font14b w-message-title">{{ title }}</div>
      </div>
      <div class="font14r w-message-content">
        <template v-if="!$slots.default">
          {{ content }}
        </template>
        <slot v-else></slot>
      </div>
    </div>
    <w-button v-if="collapse" class="w-message-btn"
      icon name="icon_arrow_down" size="xs" :icon-size="16"
      @click="toggleCollapse" />
  </div>
</template>

<script>
export default {
  name: 'w-message',
  props: {
    type: {
      type: String,
      default: 'info',
      validator(value) {
        return ['info', 'success', 'warning', 'error'].includes(value);
      },
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    collapse: Boolean,
    defaultCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: this.defaultCollapsed,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      this.$emit('toggle', this.isCollapsed);
    },
  },
};
</script>

<style lang="less" scoped>
.w-message {
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  &-main {
    display: flex;
    align-items: center;
    flex: 1 1 0;
    overflow: hidden;
  }
  &-btn {
    flex: 0 0 auto;
    margin-left: 6px;
    transform: rotate(180deg);
  }
  &-header {
    color: var(--al-gal5);
    display: flex;
    align-items: center;
  }
  &-content {
    color: var(--al-gal4);
  }
  &-icon {
    display: inline-block;
    width: 16px; height: 16px;
    margin-right: 8px;
    flex: 0 0 auto;
  }

  &--info {
    background-color: var(--nc-g10);
    .w-message-icon {
      background: center/contain no-repeat url('~@/assets/images/icon_msg_info.png');
    }
  }
  &--success {
    background-color: var(--fu-green0);
    .w-message-icon {
      background: center/contain no-repeat url('~@/assets/images/icon_msg_success.png');
    }
  }
  &--warning {
    background-color: var(--fu-yellow3);
    .w-message-icon {
      background: center/contain no-repeat url('~@/assets/images/icon_msg_warning.png');
    }
  }
  &--error {
    background-color: var(--fu-red0);
    .w-message-icon {
      background: center/contain no-repeat url('~@/assets/images/icon_msg_error.png');
    }
  }

  &.has-title {
    .w-message-main {
      flex-direction: column;
      align-items: flex-start;
    }
    .w-message {
      &-header {
        width: 100%;
      }
      &-content {
        margin-top: 6px;
      }
    }
  }

  &.is-collapsed {
    .w-message-btn {
      transform: rotate(0);
    }
    .w-message-content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}
</style>
