<template>
  <w-modal
    :show="show"
    :mask="mask"
    :z-index="zIndex"
    class="w-drawer"
    :class="{
      'is-page': type === 'page',
      'has-desc': !!description,
    }"
    @close="handleClose">
    <div
      class="w-drawer-inner"
      :class="{'is-active': active, [wrapClass]: wrapClass }"
      :style="wrapStyle">
      <template v-if="$slots.header">
        <slot name="header"></slot>
      </template>
      <div v-else-if="!hideHeader"
        class="w-drawer-header" :class="{'with-desc':description}"
        :style="{ borderBottom: type !=='page' && !headerUnderline && 'none'}">
        <div class="w-drawer-title">
          <template v-if="$slots.title">
            <slot name="title"></slot>
          </template>
          <template v-else>{{ title }}</template>
        </div>
        <div v-if="description" class="w-drawer-desc">{{ description }}</div>
      </div>
      <w-button
        v-if="!hideCloseBtn"
        class="w-drawer-close"
        type="normal-3"
        icon name="icon_close"
        :icon-size="20"
        @click="handleClose()" />
      <div class="w-drawer-content">
        <slot></slot>
      </div>
      <div v-if="$slots.footer" class="w-drawer-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </w-modal>
</template>

<script>

export default {
  props: {
    show: Boolean,
    type: {
      type: String,
      default: '',
      validator(value) {
        return ['page', ''].includes(value);
      },
    },
    title: String,
    description: String,
    hideHeader: Boolean,
    hideCloseBtn: Boolean,
    zIndex: Number,
    mask: {
      type: Boolean,
      default: true,
    },
    wrapClass: String,
    wrapStyle: [String, Object],
    headerUnderline: Boolean, // 是否需要头部下方的分割线
  },
  data() {
    return {
      active: this.show,
    };
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.active = true;
        });
      } else {
        this.active = false;
      }
    },
  },
  methods: {
    handleClose(e) {
      this.$emit('close', e);
    },
  },
};
</script>
