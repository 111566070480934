var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"w-button",class:[
    _vm.type ? 'w-button--' + _vm.type : '',
    _vm.size ? 'w-button--' + _vm.size : '',
    {
      'is-disabled': _vm.buttonDisabled,
      'is-loading': _vm.loading,
      'is-success': _vm.success,
      'is-flat': _vm.flat,
      'is-plain': _vm.plain,
      'is-icon': _vm.icon && !_vm.$slots.default,
      'with-icon': _vm.icon && _vm.$slots.default,
      'is-text': _vm.text,
      'is-focus': _vm.buttonFocus,
      'is-fill': _vm.fill,
      'is-transparent': _vm.transparent,
      'is-block': _vm.block,
    },
  ],on:{"click":_vm.handleClick}},[(_vm.loading)?_c('w-spinner',{staticClass:"w-button__state",attrs:{"size":"s","theme":_vm.type}}):_vm._e(),[(_vm.icon)?_c('w-icon',{style:({ visibility: _vm.loading || _vm.success ? 'hidden' : '' }),attrs:{"name":_vm.name,"size":_vm.iconBtnSize}}):_vm._e(),(_vm.$slots.default)?_c('span',{style:({ visibility: _vm.loading || _vm.success ? 'hidden' : '' })},[_vm._t("default")],2):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }