import { generateUrl as generateUrl2 } from './utils';

export default {
  // 用于未登录情况下验证优惠券是否可用
  getCouponCheck() {
    return generateUrl2('/api/market/coupon/buy_check', false);
  },
  // 我的优惠券列表
  getCoupons() {
    return generateUrl2('/api/market/coupon/list', false);
  },
  // 兑换优惠券
  postCouponExchange() {
    return generateUrl2('/api/market/coupon/exchange', false);
  },
  // 下单时选择优惠券
  getOrderCoupons() {
    return generateUrl2('/api/market/coupon/order_list', false);
  },
  // 下单时选择优惠券
  getCartOrderCoupons() {
    return generateUrl2('/api/market/coupon/cart_order_list', false);
  },
  // 领取PWA安装优惠券
  postGetPwaCoupon() {
    return generateUrl2('/api/market/coupon/get_pwa_coupon', false);
  },
  // 领取游戏优惠券
  postGetGameCoupon() {
    return generateUrl2('/api/market/coupon/get_game_coupon', false);
  },
  // 领取PWA安装优惠券
  getCouponBuy() {
    return generateUrl2('/api/market/coupon/buy_get', false);
  },
  getCouponBuyCart() {
    return generateUrl2('/api/market/coupon/buy_cart_get', false);
  },
};
